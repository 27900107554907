import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetService } from "./useGetService";
import { API, DashboardTabs } from "../constants";

// Third party
// import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { getUserData } from "../shared/utils";
import {
  EServiceRequestCategory,
  IServiceRequest,
  ITMDetail,
} from "../types/global.types";

export const useTransportDashboard = (
  startData: Date,
  endDate: Date,
  selectedTab: string
) => {
  console.log("useTransportDashboard", startData, endDate, selectedTab);
  const [rows, setRows] = useState([]);
  const [isRowsLoading, setRowsLoading] = useState(false);
  const vendorId = getUserData()["cognito:username"];

  // API CALL

  // FETCH SHIPBOARD HR REQUEST DETAILS
  const { response: shipboardAssigned, isLoading: isShipboardAssignedLoading } =
    useGetService(`${API.VENDOR_SERVICE_REQUEST.SHIPBOARD_HR_ARRIVED}s`);

  const { response, isLoading, refetch } = useGetService(
    `${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${selectedTab}`
  );

  // FETCH CREATED MANUAL REQUEST DETAILS
  const {
    response: manualRequestDetails,
    isLoading: ismanualRequestDetailsLoading,
    refetch: refetchManualRequest,
  } = useGetService(
    `${API.VENDOR_SERVICE_REQUEST.CREATE_MANUAL_REQUEST}s?vendorId=${vendorId}`
  );

  // FETCH TRANSPORT ASSIGNMENT
  const {
    response: serviceRequests,
    isLoading: isAssignedDataLoading,
    refetch: refetchAssinged,
  } = useGetService(
    `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}?vendorId=${vendorId}`
  );

  const isDeparture = selectedTab === DashboardTabs.DEPARTURE;
  const assignedData = useMemo(() => {
    if (isAssignedDataLoading || !Array.isArray(serviceRequests?.data)) {
      return { data: [] };
    }

    const filteredData = serviceRequests.data.filter((x: IServiceRequest) =>
      isDeparture
        ? x.requestCategory === EServiceRequestCategory.DEPARTURE
        : x.requestCategory === EServiceRequestCategory.ARRIVAL
    );

    return { data: filteredData };
  }, [serviceRequests, isAssignedDataLoading, isDeparture]);

  const getUrgentTransportationData = useCallback(() => {
    if (ismanualRequestDetailsLoading) return [];

    const urgentRequests = manualRequestDetails.data?.filter(
      (x: any) => x.isUrgentTransportRequest
    );

    const urgentRequestList = urgentRequests.map((manualRequest: any) => {
      let serviceRequestAssigned = null;

      if (manualRequest.serviceRequestId) {
        serviceRequestAssigned = assignedData.data?.find(
          (serviceRequest: any) =>
            serviceRequest.id === manualRequest.serviceRequestId
        );
      }

      return {
        id: manualRequest.tmId,
        manualRequestId: manualRequest.id,
        SCH_ARVL_DT: manualRequest.createdOn
          ? new Date(manualRequest.createdOn).toISOString().split("T")[0]
          : "",
        SCH_ARVL_TIME: manualRequest.createdOn
          ? new Date(manualRequest.createdOn).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
          : "",
        EMP_ID: manualRequest.tmId,
        isUrgentTransportRequest: true,
        FULL_NAME: "Nil",
        DEPT: "Nil",
        HOTEL_NAME: "Some Hotel",
        empName: "",
        Name: "User",
        assignedInfo: serviceRequestAssigned,
        isManualRequestCreated: true,
        Ship: {},
        FlagsOut: [],
        Port: {},
      };
    });

    return urgentRequestList;
  }, [assignedData, ismanualRequestDetailsLoading, manualRequestDetails]);

  const dataHandling = useCallback(
    (resData: ITMDetail[]) => {
      let newData: any = [];
      const data = _.cloneDeep(resData);
      data.forEach((x) => {
        const y = {
          ...x.FLIGHTDATA,
          ...x.HOTELDATA,
          ...x.PROFILEDATA,
          ...x.Assignments,
          id: x.FLIGHTDATA.EMP_ID, //uuidv4(),
          isManualRequestCreated:
            (!ismanualRequestDetailsLoading &&
              manualRequestDetails?.data &&
              manualRequestDetails?.data.some(
                (v: any) => v.tmId === x.FLIGHTDATA.EMP_ID
              )) ||
            false,
          assignedInfo:
            !isAssignedDataLoading &&
            assignedData?.data &&
            assignedData?.data.find((v: any) => v.tmId === x.FLIGHTDATA.EMP_ID),
        };

        // This code is temp once real data will come then need to remove the code
        const isShipboardScanned =
          !isShipboardAssignedLoading &&
          shipboardAssigned?.data &&
          shipboardAssigned?.data.every(
            (v: any) => v.tmId !== x.FLIGHTDATA.EMP_ID
          );

        isShipboardScanned && newData.push(y);
      });

      let finalData = [];
      if (selectedTab === DashboardTabs.ARRIVALS) {
        finalData = newData.filter(
          (x: any) => x.isManualRequestCreated === false
        );
        setRowsLoading(false);
        return finalData;
      }

      if (
        selectedTab === DashboardTabs.TRANSPORTATION_REQUEST ||
        selectedTab === DashboardTabs.MANUAL_REQUEST
      ) {
        finalData = newData.filter(
          (x: any) => x.isManualRequestCreated === true
        );
        const urgentTransportRequests = getUrgentTransportationData();
        setRowsLoading(false);
        return [...urgentTransportRequests, ...finalData];
      }

      // setTimeout(() => {
      //   setRowsLoading(false);
      // }, 2000);

      setRowsLoading(false);
      return newData;
    },
    [
      getUrgentTransportationData,
      selectedTab,
      assignedData,
      isAssignedDataLoading,
      isShipboardAssignedLoading,
      ismanualRequestDetailsLoading,
      manualRequestDetails,
      shipboardAssigned,
    ]
  );

  useEffect(() => {
    setRowsLoading(isLoading || isAssignedDataLoading);
    isLoading === false &&
      response &&
      response.data.length > 0 &&
      setRows(dataHandling(response.data));
  }, [
    response,
    isLoading,
    assignedData,
    isAssignedDataLoading,
    isShipboardAssignedLoading,
    shipboardAssigned,
    ismanualRequestDetailsLoading,
    manualRequestDetails,
    dataHandling,
  ]);

  const refetchData = (seltab: string) => {
    setRows([]);
    if (seltab === DashboardTabs.ARRIVALS) {
      setRows([]);
      refetchAssinged(
        `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}?vendorId=${vendorId}`
      );
      refetch(`${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${seltab}`);
    }
    if (seltab === DashboardTabs.DEPARTURE) {
      setRows([]);
      refetchAssinged(
        `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}?vendorId=${vendorId}`
      );
      refetch(`${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${seltab}`);
    }
    // if (seltab === DashboardTabs.PORT) {
    //   refetch(
    //     `${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${DashboardTabs.ARRIVALS}`
    //   );
    // }
    if (
      seltab === DashboardTabs.TRANSPORTATION_REQUEST ||
      seltab === DashboardTabs.MANUAL_REQUEST
    ) {
      setRows([]);
      refetchManualRequest(
        `${API.VENDOR_SERVICE_REQUEST.CREATE_MANUAL_REQUEST}s?vendorId=${vendorId}`
      );
      refetchAssinged(
        `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}?vendorId=${vendorId}`
      );
      refetch(`${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${seltab}`);
    }
  };

  return {
    rows,
    isRowsLoading,
    refetchData,
  };
};
