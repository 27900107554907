export interface ITMDetail {
  FLIGHTDATA: {
    FLIGHT_FLAG: string;
    DEST_AIRPORT_CD: string;
    ORIGIN_AIRPORT_CNTRY: string;
    SEG_ORDER: string;
    FIRSTNAME: string;
    LASTNAME: string;
    SCH_ARVL_DT: string;
    SCH_ARVL_TIME: string;
    SCH_DEP_DT: string;
    SCH_DEP_TIME: string;
    DEST_AIRPORT_CITY: string;
    DEST_AIRPORT_CNTRY: string;
    COUPON_STATUS: string;
    OPERAT_AIRLINE: string;
    OPERAT_AIRLINE_NAME: string;
    MKT_AIRLINE_NAME: string;
    PNR_NBR: string;
    FLIGHT_NBR: string;
    TICKET_NO: string;
    DEST_AIRPORT_LNG: string;
    DEST_AIRPORT_LAT: string;
    EMP_ID: string;
    BRAND: string;
    ORIGIN_AIRPORT_LNG: string;
    ORIGIN_AIRPORT_LAT: string;
    ORIGIN_AIRPORT_CITY: string;
    ORIGIN_AIRPORT: string;
    Unid: string;
  };
  HOTELDATA: {
    HOTEL_FLAG: string;
    CHECK_OUT_TIME: string;
    CHECK_IN_DATE: string;
    RES_NUMBER: string;
    HOTEL_ADDRESS: string;
    CHECK_OUT_DATE: string;
    HOTEL_NAME: string;
    EMP_ID: string;
    BRAND: string;
    CHECK_IN_TIME: string;
    PHONE_NBR: string;
    Unid: string;
  } | null;
  PROFILEDATA: {
    TRANSPORTATION_REQ: string;
    FULL_NAME: string;
    DEPT: string;
    RANK: string;
    PHONE: string;
    EMAIL: string;
    FlagsOut: Array<{
      Code: string;
      Value: string;
      Description: string;
    }>;
  };
  Assignments: {
    Ship: {
      Name: string;
      Number: string;
    };
    MovementDate: string;
    Port: {
      Code: string;
      Name: string;
    };
    Type: string;
    ConfirmationStatus: string;
    NumOfDays: string;
    Status: string;
  };
}

export enum EServiceRequestType {
  AIRPORT_TO_HOTEL = "airportToHotel",
  HOTEL_TO_AIRPORT = "hotelToAirport",
  HOTEL_TO_PORT = "hotelToPort",
  PORT_TO_HOTEL = "portToHotel",
  PORT_TO_AIRPORT = "portToAirport",
  MEET_AND_GREET = "meetAndGreet",
  HOTEL = "hotel",
  SHIPBOARD_TM_ONBOARDING = "shipboardTMOnboarding",
}

export enum EServiceRequestCategory {
  ARRIVAL = "arrival",
  DEPARTURE = "departure",
}

export enum EServiceRequestStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
}

export interface IServiceRequest {
  id: string;
  tmId: string;
  tmName?: string;
  arrivalDate: string;
  arrivalTime: string;
  vendorId: string;
  vendorEmpId: string;
  vehicleId: string;
  requestType: EServiceRequestType;
  requestCategory: EServiceRequestCategory;
  status: EServiceRequestStatus;
  paymentDone: boolean;
  isUnManifested: boolean;
  createdOn: string;
  updatedOn: string;
}
