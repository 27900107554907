import { useContext, useState } from "react";
import { GlobalContextData } from "../../../../context";
import { BaseModal, MGButton } from "../../../../components";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ShipboardHrEmployeeForm } from "./ShipboardHREmployeeForm";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export const ShipboardEmployeeInfo = () => {
  const { state } = useContext(GlobalContextData);

  const [showModal, setShowModal] = useState(false);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Employee Name", flex: 1, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phone", headerName: "Phone", flex: 1, minWidth: 150 },
    {
      field: "experienceYears",
      headerName: "Experience Years",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "supervisorName",
      headerName: "Supervisor Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "superVisorContact",
      headerName: "Supervisor Contact",
      flex: 1,
      minWidth: 150,
    },
  ];

  return (
    <div className="transfer-employee-information-container">
      <div className="top-header-info">
        <div className="section-label">Employees List</div>
        <div className="left-sec">
          <MGButton onClick={() => setShowModal(true)}>
            <AddCircleOutlineIcon />
            Add new employee
          </MGButton>
        </div>
      </div>

      <DataGrid
        rows={state.shipboardHRInfo.vendorEmployees?.items || []}
        columns={columns}
        loading={false}
        getRowId={(row) => row.email}
        hideFooter={state.shipboardHRInfo.vendorEmployees?.items?.length === 0}
        sx={{
          height:
            state.shipboardHRInfo.vendorEmployees?.items?.length === 0
              ? 200
              : "auto",
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
      />
      <BaseModal
        open={showModal}
        onClose={() => setShowModal(false)}
        className="employee-modal"
        modalTitle="Add new employee"
      >
        <ShipboardHrEmployeeForm handleClose={() => setShowModal(false)} />
      </BaseModal>
    </div>
  );
};
