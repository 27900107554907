import { useEffect, useState } from "react";
import { useGetService } from "./useGetService";
import { API, DashboardTabs } from "../constants";
import { getUserData } from "../shared/utils";

// Third party
// import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { IServiceRequest, ITMDetail } from "../types/global.types";

export const useAssignedDetails = () => {
  const [rows, setRows] = useState([]);
  const [isRowsLoading, setRowsLoading] = useState(false);

  const vendorId = getUserData()["cognito:username"];

  // API CALL ::

  // FETCH ALL DATA
  const {
    response: allResponse,
    isLoading: allResponseLoading,
    refetch: refetchAllData,
  } = useGetService(
    `${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${DashboardTabs.ARRIVALS}`
  );

  // FETCH ASSIGNMENT
  const {
    response,
    isLoading,
    refetch: refetchAssinged,
  } = useGetService(
    `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}?vendorId=${vendorId}`
  );

  useEffect(() => {
    isLoading === false &&
      allResponseLoading === false &&
      allResponse?.data.length > 0 &&
      response?.data.length > 0 &&
      dataHandling(allResponse.data, response.data);
  }, [response, isLoading, allResponseLoading, allResponse]);

  const dataHandling = (
    resData: ITMDetail[],
    assignedRes: IServiceRequest[]
  ) => {
    let newData: any = [];
    setRowsLoading(true);
    if (resData.length > 0) {
      const data = _.cloneDeep(resData);
      data.forEach((x) => {
        const y = {
          ...x.FLIGHTDATA,
          ...x.HOTELDATA,
          ...x.PROFILEDATA,
          ...x.Assignments,
        };
        newData.push(y);
      });
    }

    // CHECK ID & ASSIGN
    let finalAssignedData: any = [];
    if (newData.length > 0) {
      assignedRes.forEach((x) => {
        if (x.isUnManifested) {
          const newEntry = {
            ...x,
            EMP_ID: x.tmId,
            Name: x.tmName,
            FULL_NAME: x.tmName,
            SCH_ARVL_DT: x.arrivalDate,
            status: x.status,
            isUnManifested: true,
            id: x.id,
          };
          finalAssignedData.push(newEntry);
          return;
        }

        newData.forEach((y: any) => {
          if (x.tmId === y.EMP_ID) {
            finalAssignedData.push({ ...x, ...y });
          }
        });
      });
    }

    setRows(finalAssignedData);
    setRowsLoading(false);
  };

  const refetchData = () => {
    setRows([]);
    refetchAssinged(
      `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}?vendorId=${vendorId}`
    );
    refetchAllData(
      `${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${DashboardTabs.ARRIVALS}`
    );
  };

  return {
    rows,
    isRowsLoading,
    refetchData,
  };
};
