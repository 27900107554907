import { MGButton, MGInputBox } from "../../../../components";
import { useContext, useState } from "react";

// style
import "./ShipboardHREmployeeInfo.scss";
import { IEmployeeType } from "../../VendorInfoTypes";
import { usePostService } from "../../../../hooks";
import { API } from "../../../../constants";
import { ActionTypes, GlobalContextData } from "../../../../context";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import { EMAIL_REGEX } from "../../../../shared/constants";

type ShipboardHrEmployeeFormProps = {
  handleClose: () => void;
};

const initialData = {
  name: "",
  email: "",
  phone: "",
  experienceYears: "",
  supervisorName: "",
  superVisorContact: "",
  vendorId: "",
};

export const ShipboardHrEmployeeForm = ({
  handleClose,
}: ShipboardHrEmployeeFormProps) => {
  const { state, dispatch } = useContext(GlobalContextData);

  //Saving data
  const { postData, isSubmitting, error } = usePostService();

  const [employeeData, setEmployeeData] = useState<IEmployeeType>(initialData);

  const handleChange = (eventData: any) => {
    const { name, value } = eventData.target;
    setEmployeeData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handlePhoneChange = (data: string, fieldName: string) => {
    const eventData = {
      target: {
        name: fieldName,
        value: data,
      },
    };
    handleChange(eventData);
  };

  const handleSubmit = async () => {
    const payload = {
      ...employeeData,
      vendorId: state.shipboardHRInfo.shipboardHRDetails.id,
    };

    try {
      const response = await postData(`${API.CREATE_EMPLOYEE}`, payload);
      if (response.status === 200 && response.statusText === "OK") {
        const newSetData = [...state.shipboardHRInfo.vendorEmployees.items];
        newSetData.unshift(payload);
        const vendorEmployees = {
          items: newSetData,
        };
        dispatch({
          type: ActionTypes.SET_SHIPBOARD_HR_EMPLOYEES,
          payload: vendorEmployees,
        });
        handleClose();
        toast.info("Created employee successfully !!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {!isSubmitting && error && (
        <Alert severity="error">
          {error.message} || Some thing went wrong while creating employee!
        </Alert>
      )}
      <form>
        <div className="employee-form-box">
          <div className="form-sec-details">
            <MGInputBox
              containerClassName="form-field-width"
              label="Name"
              required
              type="text"
              placeholder="Enter name..."
              onChange={handleChange}
              name="name"
              value={employeeData.name}
            />
            <MGInputBox
              containerClassName="form-field-width"
              label="Email"
              required
              type="text"
              placeholder="Enter email..."
              onChange={handleChange}
              name="email"
              value={employeeData.email}
            />
            <MGInputBox
              containerClassName="form-field-width"
              label="Phone"
              required
              type="tel"
              placeholder="Enter phone..."
              handlePhoneChange={(data) => handlePhoneChange(data, "phone")}
              name="phone"
              value={employeeData.phone}
            />
          </div>
          <div className="form-sec-details">
            <MGInputBox
              containerClassName="form-field-width"
              required
              label="Experience years"
              type="text"
              placeholder="Enter experience years..."
              onChange={handleChange}
              name="experienceYears"
              value={employeeData.experienceYears}
            />

            <MGInputBox
              containerClassName="form-field-width"
              label="Supervisor name"
              required
              type="text"
              placeholder="Enter supervisor name..."
              onChange={handleChange}
              name="supervisorName"
              value={employeeData.supervisorName}
            />
            <MGInputBox
              containerClassName="form-field-width"
              label="Supervisor contact"
              required
              type="tel"
              placeholder="Supervisor phone..."
              handlePhoneChange={(data) =>
                handlePhoneChange(data, "superVisorContact")
              }
              name="superVisorContact"
              value={employeeData.superVisorContact}
            />
          </div>
        </div>
        <hr></hr>
        <div className="bottom-container">
          <MGButton variant="outlined" onClick={handleClose}>
            Close
          </MGButton>
          <MGButton
            className="submit-button"
            onClick={handleSubmit}
            disabled={
              isSubmitting ||
              !(
                employeeData.phone &&
                employeeData.email &&
                EMAIL_REGEX.test(employeeData.email) &&
                employeeData.name &&
                employeeData.experienceYears &&
                employeeData.superVisorContact &&
                employeeData.supervisorName
              )
            }
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </MGButton>
        </div>
      </form>
    </>
  );
};
