import React, { useContext, useMemo, useState } from "react";

// third party
import { Alert } from "@mui/material";
import { toast } from "react-toastify";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid";
import { FiDownload } from "react-icons/fi";
import { Menu, MenuItem } from "@mui/material";

// internal imports
import { API, VendorTypeService } from "../../constants";
import { useDocumentTitle } from "../../hooks";
import { useGetService } from "../../hooks/useGetService";
import { SearchInput } from "../../components/SearchInput";
import { formatDateToCustomFormat } from "../../utils/date.utils";
import { usePostService } from "../../hooks/usePostService";

// style
import "./RegisteredVendors.scss";
import { GlobalContextData } from "../../context";
import { BaseModal, MGButton } from "../../components";

// const getGridColumns = (onApprove: (row: any) => void) => [
//   { label: "Name", name: "name" },
//   { label: "Email", name: "email" },
//   { label: "Phone", name: "phoneNumber" },
//   {
//     label: "Vendor Type",
//     name: "vendorTypeName",
//   },
//   {
//     label: "Created On",
//     name: "createdOn",
//     render: (row: any) => {
//       return formatDateToCustomFormat(
//         new Date(row.createdOn * 1000).toISOString() // Multiply by 1000 to convert seconds to milliseconds
//       );
//     },
//   },
//   {
//     label: "",
//     name: "action",
//     render: (row: any) => {
//       return (
//         <MGButton className="approve-button" onClick={() => onApprove(row)}>
//           Approve
//         </MGButton>
//       );
//     },
//   },
// ];

export const RegisteredVendors = () => {
  useDocumentTitle("RegisteredVendors");
  useContext(GlobalContextData);

  const postService = usePostService();

  const [searchText, setSearchText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { response, isLoading, error, refetch } = useGetService(
    API.REGISTRATION_REQUEST.LIST + "?status=approved"
  );

  const registrationRequests = useMemo(() => {
    if (searchText) {
      return response?.items?.filter((item: any) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return response?.items || [];
  }, [response, searchText]);

  const approveRegistrationRequest = async () => {
    console.log(
      "selectedRow.vendorTypeName ",
      selectedRow.vendorTypeName,
      VendorTypeService[
        selectedRow.vendorTypeName as keyof typeof VendorTypeService
      ]
    );
    try {
      const response = await postService.postData(
        API.ADMIN.APPROVE_REGISTRATION_REQUEST,
        {
          id: selectedRow?.id,
          vendorType: selectedRow.vendorTypeName,
          // VendorTypeService[
          //   selectedRow.vendorTypeName as keyof typeof VendorTypeService
          // ],
          // VendorTypeService.HOTEL_ONLY === selectedRow.vendorTypeName
          //   ? VendorTypeService.HOTEL_ONLY
          //   : VendorTypeService.TRANSPORTATION_ONLY,
        }
      );
      if (response?.status === 200) {
        toast.success(`Vendor ${selectedRow?.name} approved successfully`);
        await refetch(API.REGISTRATION_REQUEST.LIST);
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error?.message || "Something went wrong. Please try again.");
    }
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phoneNumber", headerName: "Phone", flex: 1, minWidth: 150 },
    {
      field: "vendorTypeName",
      headerName: "Vendor Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      minWidth: 150,
      valueFormatter: (value: any) => {
        return formatDateToCustomFormat(new Date(value * 1000).toISOString());
      },

      renderCell: (params: GridRenderCellParams) => {
        const formattedDate = formatDateToCustomFormat(
          new Date(params.row.createdOn * 1000).toISOString()
        );
        return <span>{formattedDate}</span>;
      },
    },
  ];

  // CSV Export Handler
  const apiRef = useGridApiRef();
  const handleExportCsv = () => {
    if (apiRef.current) {
      apiRef.current?.exportDataAsCsv({
        fileName: "Registered_Vendors.csv",
        delimiter: ",",
        utf8WithBom: true,
        allColumns: true,
      });
    }
    setAnchorEl(null);
  };

  // Excel Export Handler
  const handleExportExcel = () => {
    if (apiRef.current) {
      apiRef.current?.exportDataAsCsv({
        fileName: "Registered_Vendors.xlsx",
        delimiter: ",",
        utf8WithBom: true,
        allColumns: true,
      });
    }
    setAnchorEl(null); // Close the menu after selection
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for menu
  };

  // Handle menu close
  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  return (
    <section className="reg-request-container">
      <div>
        <h3 className="section-label">Registered Vendors</h3>
      </div>
      {!isLoading && error && (
        <Alert severity="error">
          Some thing went wrong. While Fetching Vendor Registation request. -{" "}
          <strong>{error as unknown as string}!</strong>
        </Alert>
      )}
      <div className="search-box">
        <SearchInput
          value={searchText}
          onChange={(text) => setSearchText(text)}
        />
        <MGButton onClick={handleClick}>
          Export
          <FiDownload className="export-icon" />
        </MGButton>

        <Menu
          anchorEl={anchorEl} // Anchor the menu relative to the button
          open={open} // Menu opens when anchorEl is set
          onClose={handleClose} // Close the menu when clicked outside
        >
          <MenuItem onClick={handleExportCsv}>Download as CSV</MenuItem>
          <MenuItem onClick={handleExportExcel}>Download as Excel</MenuItem>
        </Menu>
      </div>
      {/* <Grid
        columns={getGridColumns(onApprove)}
        rows={registrationRequests}
        isLoading={isLoading}
        enableSorting={true}
        sortableKey={["Name", "Email", "Phone", "Vendor Type", "Created On"]}
      /> */}

      <DataGrid
        rows={registrationRequests || []}
        sx={{ height: registrationRequests.length === 0 ? 200 : "auto" }}
        columns={columns}
        loading={isLoading}
        apiRef={apiRef}
        hideFooter={registrationRequests.length === 0}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
      />

      <BaseModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        className="partner-modal"
        modalTitle="Please confirm"
      >
        <div className="partner-modal-container ">
          Are you sure you want to approve the registration request for this
          vendor? Once approved, the vendor will gain access to the platform.
        </div>
        <hr></hr>
        <div className="bottom-container">
          <MGButton variant="outlined" onClick={() => setShowAlert(false)}>
            Close
          </MGButton>
          <MGButton
            className="submit-button"
            onClick={() => {
              setShowAlert(false);
              approveRegistrationRequest();
            }}
          >
            Confirm
          </MGButton>
        </div>
      </BaseModal>
    </section>
  );
};
