import { useContext, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import _ from "lodash";

// Internal imports
import { MGButton, MGInputBox, SelectDropdown } from "../../../../components";

// Constants
import { API, countries, currencies } from "../../../../constants";

// Type
import { IShipboardProfileDataType } from "../../VendorInfoTypes";

// Style
import "./ShipboardHRProfile.scss";
import { usePostService } from "../../../../hooks";
import { ActionTypes, GlobalContextData } from "../../../../context";
import { toast } from "react-toastify";

export const ShipboardHRProfile = () => {
  const {
    postData,
    isSubmitting,
    error: errorOccuredWhileSubmitting,
  } = usePostService();

  const { state, dispatch } = useContext(GlobalContextData);
  // Form related
  const [shipboardHRDetails, setShipboardHrDetails] = useState(
    _.cloneDeep(state?.shipboardHRInfo?.shipboardHRDetails)
  );
  const [warningMessage, setWarningMessage] = useState("");
  const [submitButtonStatus, setSubmitButtonStatus] = useState(true);

  useEffect(() => {
    setShipboardHrDetails(
      _.cloneDeep(state?.shipboardHRInfo?.shipboardHRDetails)
    );
  }, [state?.shipboardHRInfo?.shipboardHRDetails]);

  useEffect(() => {
    if (
      _.isEqual(state?.shipboardHRInfo?.shipboardHRDetails, shipboardHRDetails)
    ) {
      setSubmitButtonStatus(true);
      return;
    }

    setSubmitButtonStatus(!validateForm(shipboardHRDetails));
  }, [shipboardHRDetails, state?.shipboardHRInfo?.shipboardHRDetails]);

  const validateForm = (data: IShipboardProfileDataType) => {
    let valid = false;
    if (
      data.name &&
      data.phoneNumber &&
      data.supportPhone &&
      data.line1 &&
      data.line2 &&
      data.city &&
      data.country &&
      data.state &&
      data.postalCode &&
      data.currency
    ) {
      valid = true;
    }
    return valid;
  };

  const handleChange = (eventData: any) => {
    const { name, value } = eventData.target;
    setShipboardHrDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSelect = (data: any, fieldName: string) => {
    const eventData = {
      target: {
        name: fieldName,
        value: data.value,
      },
    };
    handleChange(eventData);
  };

  const handlePhoneChange = (data: string, fieldName: string) => {
    const eventData = {
      target: {
        name: fieldName,
        value: data,
      },
    };
    handleChange(eventData);
  };

  const handleUpdate = async () => {
    const {
      email,
      vendorTypeName,
      vendorTypeId,
      roleId,
      createdOn,
      id,
      ship,
      brandServicedName,
      brandServiced,
      isRegistered,
      pictures,
      profileImageUrl,
      ...rest
    } = shipboardHRDetails;
    try {
      dispatch({
        type: ActionTypes.SET_LOADING,
        payload: {
          isLoading: true,
          loadingText: "Updating vendor information",
        },
      });
      const response = await postData(
        `${API.SHIPBOARD_HR.UPDATE_SHIPBOARD_PROFILE_DATA_BY_VENDOR_ID}/${id}`,
        rest,
        "PUT"
      );
      if (response.status === 200 && response.statusText === "OK") {
        dispatch({
          type: ActionTypes.SET_SHIPBOARD_HR_DETAILS,
          payload: response.data,
        });

        toast.info("Updated data successfully !!");
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({
        type: ActionTypes.SET_LOADING,
        payload: {
          isLoading: false,
          loadingText: "Updating vendor information",
        },
      });
    }
  };

  return (
    <div className="vendor-form-section-container">
      {warningMessage && (
        <Alert severity="warning" onClose={() => setWarningMessage("")}>
          {warningMessage}
        </Alert>
      )}

      {errorOccuredWhileSubmitting && (
        <Alert severity="error" onClose={() => setWarningMessage("")}>
          {errorOccuredWhileSubmitting.message ||
            "Something went wrong while updating the profile !"}
        </Alert>
      )}
      <div className="form-container-sec">
        <div className="form-each-section">
          <div className="form-each-section-label">PROFILE INFORMATION</div>
          <MGInputBox
            label="Name"
            required
            type="text"
            placeholder="Enter your name..."
            onChange={handleChange}
            name="name"
            value={shipboardHRDetails.name}
          />

          <MGInputBox
            label="Email"
            required
            type="text"
            placeholder="Enter Email..."
            onChange={handleChange}
            disabled
            name="email"
            value={shipboardHRDetails.email}
          />

          <MGInputBox
            label="Office contact number"
            required
            type="tel"
            placeholder="Enter Phone..."
            handlePhoneChange={(data) => handlePhoneChange(data, "phoneNumber")}
            name="phoneNumber"
            value={shipboardHRDetails.phoneNumber}
          />

          <MGInputBox
            label="Dispatcher contact number"
            required
            type="tel"
            placeholder="Support Phone..."
            handlePhoneChange={(data) =>
              handlePhoneChange(data, "supportPhone")
            }
            name="supportPhone"
            value={shipboardHRDetails.supportPhone}
          />

          <MGInputBox
            label="Brand"
            required
            type="text"
            disabled
            placeholder="Vendor Brand..."
            name="brandServiced"
            value={shipboardHRDetails?.brandServicedName}
          />

          <MGInputBox
            label="Ship"
            required
            type="text"
            disabled
            placeholder="Ship"
            name="ship"
            value={shipboardHRDetails?.ship}
          />
        </div>

        <div className="form-each-section">
          <div className="form-each-section-label">Office Address Details</div>
          <MGInputBox
            label="Street address"
            required
            type="text"
            placeholder="Enter street address..."
            onChange={handleChange}
            name="line1"
            value={shipboardHRDetails?.line1}
          />
          <MGInputBox
            label="Line two"
            required
            type="text"
            placeholder="Enter line two..."
            onChange={handleChange}
            name="line2"
            value={shipboardHRDetails?.line2}
          />
          <MGInputBox
            label="City"
            required
            type="text"
            placeholder="Enter city..."
            onChange={handleChange}
            name="city"
            value={shipboardHRDetails?.city}
          />
          <MGInputBox
            label="Postal code"
            required
            type="text"
            placeholder="Enter postal code..."
            onChange={handleChange}
            name="postalCode"
            value={shipboardHRDetails?.postalCode}
          />

          <MGInputBox
            label="State / Province"
            required
            type="text"
            placeholder="Enter state..."
            onChange={handleChange}
            name="state"
            value={shipboardHRDetails?.state}
          />

          <SelectDropdown
            label="Country"
            required
            options={countries}
            name="country"
            onChange={(data) => handleSelect(data, "country")}
            placeholder="Select country..."
            value={countries.find(
              (country) =>
                shipboardHRDetails?.country === country.label && {
                  value: country.label,
                  label: country.label,
                }
            )}
          />
        </div>
        <div className="form-each-section">
          <div className="form-each-section-label">Support</div>
          <MGInputBox
            label="Hours of support "
            type="text"
            placeholder="Enter hours of support..."
            onChange={handleChange}
            name="hoursOfSupport"
            value={shipboardHRDetails?.hoursOfSupport}
          />
          <MGInputBox
            label="Over time hours"
            type="text"
            placeholder="Enter over time hours..."
            onChange={handleChange}
            name="overtimeHours"
            value={shipboardHRDetails?.overtimeHours}
          />
          <SelectDropdown
            label="Currency"
            required
            options={currencies}
            name="currency"
            onChange={(data) => handleSelect(data, "currency")}
            placeholder="Select currency..."
            value={currencies.find(
              (currency) =>
                shipboardHRDetails?.currency === currency.label && {
                  value: currency.label,
                  label: currency.label,
                }
            )}
          />
        </div>
      </div>
      <div className="save-button">
        <MGButton
          className="save-btn"
          onClick={handleUpdate}
          disabled={submitButtonStatus}
        >
          {isSubmitting ? "Updating..." : "Update changes"}
        </MGButton>
      </div>
    </div>
  );
};
