import { lazy } from "react";
import { VendorProfileDetails } from "../../pages/VendorProfileDetails";

//icons
import { RiHomeLine } from "react-icons/ri";
import {
  MdDashboard,
  MdOutlineMiscellaneousServices,
  MdLocalAirport,
} from "react-icons/md";
import { FaClipboardList, FaRegHandshake } from "react-icons/fa6";
import { RiShipLine } from "react-icons/ri";
import { HiOutlineIdentification } from "react-icons/hi2";
import { FaTruck, FaHotel } from "react-icons/fa";

// project imports
import { Loadable } from "../../layout";
import { UserRole, VendorTypeService } from "../../constants";
import { GroupIds, RoutePath } from "../routesPathConfig";
// import cruise from "../../resources/images/cruise1.png";
// import SVGBrand from "../../resources/images/brand";
import Settings from "../../pages/Settings/Settings";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { TbShip } from "react-icons/tb";
import { RegisteredVendors } from "../../pages/RegisteredVendors";
import { FaRegIdBadge } from "react-icons/fa";
import { ShipboardHRProfile } from "../../pages/VendorProfileDetails/ShipboardHRDeatils/ShipboardHrProfile";
import { ShipboardEmployeeInfo } from "../../pages/VendorProfileDetails/ShipboardHRDeatils/ShipboardHREmployeeInfo";

export type ScopeRouteType = {
  path: string;
  element: React.ReactNode;
  scopes: UserRole[];
  label?: string;
  icon?: React.ReactNode;
  group?: string;
  count?: string;
  notvisible?: string[];
};

const Dashboard = Loadable(
  lazy(() =>
    import("../../pages/Dashboard").then((module) => ({
      default: module.Dashboard,
    }))
  )
);

const LocalPartners = Loadable(
  lazy(() =>
    import("../../pages/LocalPartners").then((module) => ({
      default: module.LocalPartners,
    }))
  )
);

const UserProfile = Loadable(
  lazy(() =>
    import("../../pages/UserProfile").then((module) => ({
      default: module.UserProfile,
    }))
  )
);
const Alerts = Loadable(
  lazy(() =>
    import("../../pages/Alerts").then((module) => ({
      default: module.Alerts,
    }))
  )
);

// const CarnivalAdmins = Loadable(
//   lazy(() =>
//     import("../../pages/CarnivalAdmins").then((module) => ({
//       default: module.CarnivalAdmins,
//     }))
//   )
// );

const ServiceRequests = Loadable(
  lazy(() =>
    import("../../pages/ServiceRequests").then((module) => ({
      default: module.ServiceRequests,
    }))
  )
);

const VendorType = Loadable(
  lazy(() =>
    import("../../pages/VendorType").then((module) => ({
      default: module.VendorType,
    }))
  )
);

// const TypeServices = Loadable(
//   lazy(() =>
//     import("../../pages/TypeServices").then((module) => ({
//       default: module.TypeServices,
//     }))
//   )
// );

const Ports = Loadable(
  lazy(() =>
    import("../../pages/Ports").then((module) => ({
      default: module.Ports,
    }))
  )
);

const Airport = Loadable(
  lazy(() =>
    import("../../pages/Airport").then((module) => ({
      default: module.Airport,
    }))
  )
);

const Brand = Loadable(
  lazy(() =>
    import("../../pages/Brand").then((module) => ({
      default: module.Brand,
    }))
  )
);

const VehicleCategory = Loadable(
  lazy(() =>
    import("../../pages/VehicleCategory").then((module) => ({
      default: module.VehicleCategory,
    }))
  )
);

const Amenities = Loadable(
  lazy(() =>
    import("../../pages/Amenities").then((module) => ({
      default: module.Amenities,
    }))
  )
);

// const Contract = Loadable(
//   lazy(() =>
//     import("../../pages/Contract").then((module) => ({
//       default: module.Contract,
//     }))
//   )
// );

const HotelProfile = Loadable(
  lazy(() =>
    import("../../pages/VendorProfileDetails/HotelDetails/HotelProfile").then(
      (module) => ({
        default: module.HotelProfile,
      })
    )
  )
);

const HotelAmenities = Loadable(
  lazy(() =>
    import("../../pages/VendorProfileDetails/HotelDetails/HotelAmenities").then(
      (module) => ({
        default: module.HotelAmenities,
      })
    )
  )
);
const ContactInformation = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/HotelDetails/ContactInformation"
    ).then((module) => ({
      default: module.ContactInformation,
    }))
  )
);
const MealAllowance = Loadable(
  lazy(() =>
    import("../../pages/VendorProfileDetails/HotelDetails/MealAllowance").then(
      (module) => ({
        default: module.MealAllowance,
      })
    )
  )
);
const RoomProjections = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/HotelDetails/RoomProjections"
    ).then((module) => ({
      default: module.RoomProjections,
    }))
  )
);

const EmployeesInformation = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/HotelDetails/EmployeesInformation"
    ).then((module) => ({
      default: module.EmployeesInformation,
    }))
  )
);

const TransportProfile = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportDetails/TransportProfile"
    ).then((module) => ({
      default: module.TransportProfile,
    }))
  )
);

const TransportContractInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportDetails/TransportContractInfo"
    ).then((module) => ({
      default: module.TransportContractInfo,
    }))
  )
);

const TransportEmployeeInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportDetails/TransportEmployeeInfo"
    ).then((module) => ({
      default: module.TransportEmployeeInfo,
    }))
  )
);

const TransportVehiclesInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportDetails/TransportVehiclesInfo"
    ).then((module) => ({
      default: module.TransportVehiclesInfo,
    }))
  )
);

const TransportAmenites = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportDetails/TransportAmenites"
    ).then((module) => ({
      default: module.TransportAmenites,
    }))
  )
);

const Assignment = Loadable(
  lazy(() =>
    import("../../pages/Assignment").then((module) => ({
      default: module.Assignment,
    }))
  )
);

// const Invoicing = Loadable(
//   lazy(() =>
//     import("../../pages/Invoicing").then((module) => ({
//       default: module.Invoicing,
//     }))
//   )
// );

const InvoiceDetails = Loadable(
  lazy(() =>
    import("../../pages/InvoiceDetails").then((module) => ({
      default: module.InvoiceDetails,
    }))
  )
);

// MEET GREET Routing ::
const MeetGreetProfile = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/MeetGreetDetails/MeetGreetProfile"
    ).then((module) => ({
      default: module.MeetGreetProfile,
    }))
  )
);

const MeetGreetAmenites = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/MeetGreetDetails/MeetGreetAmenites"
    ).then((module) => ({
      default: module.MeetGreetAmenites,
    }))
  )
);

const MeetGreetContractInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/MeetGreetDetails/MeetGreetContractInfo"
    ).then((module) => ({
      default: module.MeetGreetContractInfo,
    }))
  )
);

const MeetGreetEmployeeInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/MeetGreetDetails/MeetGreetEmployeeInfo"
    ).then((module) => ({
      default: module.MeetGreetEmployeeInfo,
    }))
  )
);

const MeetGreetVehiclesInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/MeetGreetDetails/MeetGreetVehiclesInfo"
    ).then((module) => ({
      default: module.MeetGreetVehiclesInfo,
    }))
  )
);

// TRANSPORT MEET GREET Routing ::
const TransportationMeetGreetProfile = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportationMeetGreetDetails/TransportationMeetGreetProfile"
    ).then((module) => ({
      default: module.TransportationMeetGreetProfile,
    }))
  )
);

const TransportationMeetGreetAmenites = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportationMeetGreetDetails/TransportationMeetGreetAmenites"
    ).then((module) => ({
      default: module.TransportationMeetGreetAmenites,
    }))
  )
);

const TransportationMeetGreetContractInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportationMeetGreetDetails/TransportationMeetGreetContractInfo"
    ).then((module) => ({
      default: module.TransportationMeetGreetContractInfo,
    }))
  )
);

const TransportationMeetGreetEmployeeInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportationMeetGreetDetails/TransportationMeetGreetEmployeeInfo"
    ).then((module) => ({
      default: module.TransportationMeetGreetEmployeeInfo,
    }))
  )
);

const TransportationMeetGreetVehiclesInfo = Loadable(
  lazy(() =>
    import(
      "../../pages/VendorProfileDetails/TransportationMeetGreetDetails/TransportationMeetGreetVehiclesInfo"
    ).then((module) => ({
      default: module.TransportationMeetGreetVehiclesInfo,
    }))
  )
);

//TODO ::
// const NotFoundPage = Loadable(
//   lazy(() =>
//     import("../../pages/NotFoundPage").then((module) => ({
//       default: module.NotFoundPage,
//     }))
//   )
// );

export const allRoutes = [
  {
    path: RoutePath.DASHBOARD,
    element: <Dashboard />,
    scopes: [UserRole.SUPER_ADMIN, UserRole.VENDOR],
    label: "Dashboard",
    icon: <MdDashboard />,
    group: GroupIds.MANAGEMENT,
  },
  {
    path: RoutePath.ASSIGNMENT,
    element: <Assignment />,
    scopes: [UserRole.VENDOR],
    label: "Assignments",
    icon: <FaClipboardList />,
    group: GroupIds.MANAGEMENT,
    notvisible: [
      VendorTypeService.HOTEL_ONLY,
      VendorTypeService.TM_CENTER,
      VendorTypeService.SHIPBOARD_HR,
    ],
  },

  // {
  //   path: RoutePath.INVOICING,
  //   element: <Invoicing />,
  //   scopes: [UserRole.VENDOR],
  //   label: "Invoicing",
  //   icon: <FaFileInvoiceDollar />,
  //   group: GroupIds.MANAGEMENT,
  // },
  {
    path: RoutePath.INVOICE_DETAILS,
    element: <InvoiceDetails />,
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.VENDOR_REQUEST,
    element: <LocalPartners />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Vendor requests",
    icon: <FaRegHandshake />,
    group: GroupIds.MANAGEMENT,
    count: "0",
  },
  {
    path: RoutePath.REGISTERED_VENDORS,
    element: <RegisteredVendors />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Registered vendors",
    icon: <FaRegIdBadge />,
    group: GroupIds.SYSTEM_CONFIGURATION,
  },
  {
    path: RoutePath.ALERTS,
    element: <Alerts />,
    scopes: [UserRole.SUPER_ADMIN, UserRole.VENDOR],
    label: "Alerts",
    icon: <HiOutlineBellAlert />,
    group: GroupIds.MANAGEMENT,
  },

  /**
   * Start for vendor: HOTEL TAB SECTION
   */
  {
    path: RoutePath.HOTEL_PROFILE_BY_ID,
    element: (
      <VendorProfileDetails>
        <HotelProfile />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.SHIPBOARD_HR_PROFILE_BY_ID,
    element: (
      <VendorProfileDetails>
        <ShipboardHRProfile />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.HOTEL_CONTACT_INFORMATION_BY_ID,
    element: (
      <VendorProfileDetails>
        <ContactInformation />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.HOTEL_AMENITIES_BY_ID,
    element: (
      <VendorProfileDetails>
        <HotelAmenities />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.HOTEL_MEAL_ALLOWANCE_BY_ID,
    element: (
      <VendorProfileDetails>
        <MealAllowance />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.HOTEL_ROOM_PROJECTIONS_BY_ID,
    element: (
      <VendorProfileDetails>
        <RoomProjections />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.HOTEL_EMPLOYEES_BY_ID,
    element: (
      <VendorProfileDetails>
        <EmployeesInformation />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  // ==============================|| END for vendor: HOTEL TAB SECTION ||============================== //

  /**
   * START for vendor: TRANSPORT TAB SECTION
   */

  {
    path: RoutePath.TRANSPORT_PROFILE_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportProfile />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.TRANSPORT_CONTACT_INFORMATION_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportContractInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.TRANSPORT_EMPLOYEE_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportEmployeeInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.SHIPBOARD_HR_EMPLOYEE_BY_ID,
    element: (
      <VendorProfileDetails>
        <ShipboardEmployeeInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.TRANSPORT_VEHICLE_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportVehiclesInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.TRANSPORT_AMENITIES_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportAmenites />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  // ==============================|| END for vendor: TRANSPORT TAB SECTION||============================== //

  /**
   * START for vendor: MEET GREET TAB SECTION
   */

  {
    path: RoutePath.MEET_GREET_PROFILE_BY_ID,
    element: (
      <VendorProfileDetails>
        <MeetGreetProfile />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.MEET_GREET_CONTACT_INFORMATION_BY_ID,
    element: (
      <VendorProfileDetails>
        <MeetGreetContractInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.MEET_GREET_EMPLOYEE_BY_ID,
    element: (
      <VendorProfileDetails>
        <MeetGreetEmployeeInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.MEET_GREET_VEHICLE_BY_ID,
    element: (
      <VendorProfileDetails>
        <MeetGreetVehiclesInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.MEET_GREET_AMENITIES_BY_ID,
    element: (
      <VendorProfileDetails>
        <MeetGreetAmenites />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  // ==============================|| END for vendor: MEET GREET TAB SECTION||============================== //

  /**
   * START for vendor: TRANSPORTATION MEET GREET TAB SECTION
   */

  {
    path: RoutePath.TRANSPORT_MEET_GREET_PROFILE_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportationMeetGreetProfile />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.TRANSPORT_MEET_GREET_CONTACT_INFORMATION_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportationMeetGreetContractInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },
  {
    path: RoutePath.TRANSPORT_MEET_GREET_EMPLOYEE_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportationMeetGreetEmployeeInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.TRANSPORT_MEET_GREET_VEHICLE_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportationMeetGreetVehiclesInfo />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  {
    path: RoutePath.TRANSPORT_MEET_GREET_AMENITIES_BY_ID,
    element: (
      <VendorProfileDetails>
        <TransportationMeetGreetAmenites />
      </VendorProfileDetails>
    ),
    scopes: [UserRole.VENDOR],
  },

  // ==============================|| END for vendor:TRANSPORTATION MEET GREET TAB SECTION||============================== //

  {
    path: RoutePath.SUPER_ADMIN_PROFILE,
    element: <UserProfile />,
    scopes: [UserRole.SUPER_ADMIN, UserRole.VENDOR, UserRole.CARNVAL_ADMIN],
    label: "User profile",
    icon: <RiHomeLine />,
  },
  // {
  //   path: "/carnival-admins",
  //   element: <CarnivalAdmins />,
  //   scopes: [UserRole.SUPER_ADMIN],
  //   label: "Carnival Admins",
  //   icon: <RiAdminLine />,
  //   group: GroupIds.MANAGEMENT,
  // },
  {
    path: RoutePath.SERVICE_REQUESTS,
    element: <ServiceRequests />,
    scopes: [UserRole.CARNVAL_ADMIN],
    label: "Service Requests",
    icon: <MdOutlineMiscellaneousServices />,
    group: GroupIds.MANAGEMENT,
  },
  {
    path: RoutePath.VENDOR_TYPE_CONFIG,
    element: <VendorType />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Vendor type",
    icon: <HiOutlineIdentification />,
    group: GroupIds.SYSTEM_CONFIGURATION,
  },
  // {
  //   path: "/type-services",
  //   element: <TypeServices />,
  //   scopes: [UserRole.SUPER_ADMIN],
  //   label: "Type of Service",
  //   icon: <MdManageAccounts />,
  //   group: GroupIds.SYSTEM_CONFIGURATION,
  // },
  {
    path: RoutePath.PORT_CONFIG,
    element: <Ports />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Ports",
    icon: <RiShipLine />,
    group: GroupIds.SYSTEM_CONFIGURATION,
  },
  {
    path: RoutePath.AIRPORT_CONFIG,
    element: <Airport />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Airport",
    icon: <MdLocalAirport />,
    group: GroupIds.SYSTEM_CONFIGURATION,
  },
  {
    path: RoutePath.BRAND_CONFIG,
    element: <Brand />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Brand",
    icon: <TbShip />,
    group: GroupIds.SYSTEM_CONFIGURATION,
  },
  {
    path: RoutePath.VEHICLE_CATEGORY_CONFIG,
    element: <VehicleCategory />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Vehicle category",
    icon: <FaTruck />,
    group: GroupIds.SYSTEM_CONFIGURATION,
  },
  {
    path: RoutePath.AMENITIES_CONFIG,
    element: <Amenities />,
    scopes: [UserRole.SUPER_ADMIN],
    label: "Amenities",
    icon: <FaHotel />,
    group: GroupIds.SYSTEM_CONFIGURATION,
  },
  {
    path: RoutePath.SETTINGS,
    element: <Settings />,
    scopes: [UserRole.SUPER_ADMIN, UserRole.VENDOR],
  },
  // {
  //   path: "/contract",
  //   element: <Contract />,
  //   scopes: [UserRole.SUPER_ADMIN],
  //   label: "Contract",
  //   icon: <FaFileSignature />,
  //   group: GroupIds.SYSTEM_CONFIGURATION,
  // },
];
